import * as React from "react";
import Layout from "../components/Layout";
import PortfolioItem from "../components/PortfolioItem";

import { DiCodepen, DiGithubAlt } from "react-icons/di";
import { VscTwitter } from "react-icons/vsc";
import { GoChevronRight } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";

import { graphql, Link, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allContentfulPortfolioItem(
      filter: { featured: { eq: true } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        title
        key: id
        subtitle
        slug
        featuredImage {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

const isBrowser = typeof window !== "undefined";

const IndexPage = () => {
  const {
    allContentfulPortfolioItem: { nodes: portfolioItems },
  } = useStaticQuery(query);

  // 🫣
  function showThemeSelector() {
    if (isBrowser) {
      document.querySelector("button.about-this-design-change").click();
    }
  }

  return (
    <Layout pageTitle="Home">
      <div data-section="home" className="home main-section">
        <div className="main-section-inner text-container">
          <div className="home-intro home-section">
            <div className="home-section-inner">
              <div className="bubble">
                <h2>
                  <span className="hi">I design with</span>{" "}
                  <span className="css">code</span>
                  <span className="period">.</span>
                </h2>
                <p>
                  I'm a developer with a heavy background in design. My
                  experience across the product lifespan - from ideation to
                  engineering - allows me to create interfaces that are not only
                  functional and accessible, but also polished and visually
                  engaging. I speak the language of both design{" "}
                  <span className="amp">&amp;</span> engineering teams, and am
                  particularly interesting in employing my cross-functional
                  skillset to alleviate friction during product iteration
                  cycles.
                </p>
                {/* <p>
                  I'm a designer who uses code as my primary medium. In creating
                  interfaces this way, I cut out the process of creating
                  non-browser based mockups, iterate incredibly fast on live
                  code, and then act as a liaison between design{" "}
                  <span className="amp">&amp;</span> development teams
                  throughout the product cycle.
                </p> */}
                <p>
                  This website is a fun way to show my 10+ years of design
                  experience working across a wide array of visual styles and
                  layouts. Try out some{" "}
                  <button
                    onClick={showThemeSelector}
                    className="homepage-show-theme-select reset-button"
                  >
                    different themes
                  </button>
                  , view my <Link to="/portfolio/">portfolio</Link>, or read
                  more <Link to="/about/">about me</Link>. Open your console for
                  a message about the tech behind this site.
                </p>
              </div>
            </div>
          </div>
          <div className="home-portfolio home-section">
            <div className="home-section-inner">
              <h2>
                Selected Work{" "}
                <a className="nav-link" href="/portfolio">
                  View all
                  <GoChevronRight />
                </a>
              </h2>
              <div className="portfolio-items">
                {portfolioItems.map((portfolioItem) => (
                  <PortfolioItem {...portfolioItem} />
                ))}
              </div>
            </div>
          </div>
          <div className="home-contact home-section">
            <div className="home-section-inner">
              <h2>Contact</h2>
              <ul>
                <li>
                  <a href="mailto:alex@alextebbs.com">
                    <HiOutlineMail />
                    <span className="label">Email</span>
                    <span className="value">alex@alextebbs.com</span>
                  </a>
                </li>
                <li>
                  <a href="http://www.codepen.io/alextebbs">
                    <DiCodepen />
                    <span className="label">Codepen</span>
                    <span className="value">codepen.io/alextebbs</span>
                  </a>
                </li>
                <li>
                  <a href="http://www.github.com/alextebbs">
                    <DiGithubAlt />
                    <span className="label">Github</span>
                    <span className="value">@alextebbs</span>
                  </a>
                </li>
                <li>
                  <a href="http://www.twitter.com/ajtebbs">
                    <VscTwitter />
                    <span className="label">Twitter</span>
                    <span className="value">@ajtebbs</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
